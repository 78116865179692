/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>App</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Main'
        fontIcon='bi-chat-left'
        icon='/media/icons/custom/circle.svg'
      >
        <AsideMenuItem
          to='/empresa'
          title='Empresa'
          icon='/media/icons/custom/build.svg'
          fontIcon='bi-hospital'
        />
        <AsideMenuItem
          to='/apps/personas/lista'
          title='Personas'
          icon='/media/icons/custom/persona.svg'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Costos'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/finance/fin010.svg'
      >
        <AsideMenuItemWithSub
          to='/empresa'
          title='Insumos'
          icon='/media/icons/custom/carreta.svg'
          fontIcon='bi-hospital'
        >
          <AsideMenuItem
            to='/apps/recursos/lista'
            title='Recursos'
            icon='/media/icons/duotune/ecommerce/ecm009.svg'
          />
          <AsideMenuItem to='/grupos' title='Grupos' icon='/media/icons/custom/group2.svg' />
          <AsideMenuItem
            to='/apps/matrices/lista'
            title='Matrices | P.Unitarios'
            icon='/media/icons/custom/group3.svg'
          />
          <AsideMenuItem
            to='/equivalentes'
            title='Equivalentes'
            icon='/media/icons/duotune/abstract/abs040.svg'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Presupuesto'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/finance/fin007.svg'
        >
          <AsideMenuItem
            to='/capitulos'
            title='Capitulos'
            icon='/media/icons/duotune/text/txt009.svg'
          />
          <AsideMenuItem
            to='/partidas'
            title='Partidas'
            icon='/media/icons/duotune/ecommerce/ecm008.svg'
          />
          <AsideMenuItem to='/persona' title='Cambios' icon='/media/icons/duotune/art/art005.svg' />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/proyecto'
        title='Proyecto'
        fontIcon='bi-chat-left'
        // icon='/media/icons/duotune/finance/fin006.svg'
        icon='/media/icons/custom/machine.svg'
      >
        <AsideMenuItem
          to='apps/clientes/lista'
          title='Clientes'
          icon='/media/icons/duotune/finance/fin006.svg'
          fontIcon='bi-hospital'
        />
        <AsideMenuItem
          to='/apps/proyectos/lista'
          title='Proyectos'
          icon='/media/icons/duotune/art/art002.svg'
          fontIcon='bi-hospital'
        />
        <AsideMenuItem
          to='/bodega'
          title='Bodegas'
          // icon='/media/icons/duotune/general/gen001.svg'
          icon='/media/icons/custom/warehouse.svg'
          fontIcon='bi-hospital'
        />
        <AsideMenuItemWithSub
          to='/ejecucion'
          title='Ejecucion'
          icon='/media/icons/duotune/coding/cod004.svg'
          fontIcon='bi-hospital'
        >
          <AsideMenuItem to='/persona' title='Planificacion' icon='/media/icons/custom/plan.svg' />
          <AsideMenuItem
            to='/avance'
            title='Avance'
            icon='/media/icons/duotune/graphs/gra012.svg'
          />
          <AsideMenuItem
            to='/persona'
            title='Estimacion'
            icon='/media/icons/custom/estimacion.svg'
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/proveeduria'
        title='Proveeduria'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/ecommerce/ecm006.svg'
      >
        <AsideMenuItem
          to='apps/proveedores/lista'
          title='Proveedores'
          icon='/media/icons/custom/store2.svg'
        />
        <AsideMenuItem to='/marcas' title=' Marcas' icon='/media/icons/custom/copyright.svg' />
        <AsideMenuItem
          to='apps/precios/lista'
          title='Precios'
          icon='/media/icons/custom/precio.svg'
        />

        <AsideMenuItem
          to='/apps/requerimientos/lista'
          title='Requisiciones'
          icon='/media/icons/custom/request.svg'
          fontIcon='bi-hospital'
        />
        <AsideMenuItemWithSub
          to='/compras'
          title='Compras'
          icon='/media/icons/duotune/ecommerce/ecm001.svg'
          fontIcon='bi-hospital'
        >
          <AsideMenuItem
            to='/apps/ordenes/lista'
            title='Ordenes'
            icon='/media/icons/custom/order.svg'
            fontIcon='bi-hospital'
          />
          <AsideMenuItem
            to='/recepciones'
            title='Recepciones'
            icon='/media/icons/custom/inbox.svg'
            fontIcon='bi-hospital'
          />
          <AsideMenuItem
            to='/compras'
            title='Envios'
            icon='/media/icons/custom/outbox.svg'
            fontIcon='bi-hospital'
          />
        </AsideMenuItemWithSub>
        <AsideMenuItem
          to='reports/compras/params'
          title='Reportes'
          icon='/media/icons/custom/report.svg'
        />
        <AsideMenuItem
          to='/apps/ordenes/consulta'
          title='Consulta'
          icon='/media/icons/duotune/general/gen031.svg'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Nomina'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com005.svg'
      >
        <AsideMenuItem
          to='apps/empleados/lista'
          title='Empleados'
          // icon='/media/icons/duotune/communication/com006.svg'
          icon='/media/icons/custom/users.svg'
          fontIcon='bi-hospital'
        />
        <AsideMenuItem
          to='/empresa'
          title='Grupos'
          icon='/media/icons/custom/group2.svg'
          fontIcon='bi-hospital'
        />
        <AsideMenuItem to='/persona' title='Planillas' icon='/media/icons/custom/payroll.svg' />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Configuracion'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/coding/cod001.svg'
      >
        <AsideMenuItem
          to='/empresa'
          title='Usuarios'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-hospital'
        />
        <AsideMenuItem
          to='/builder'
          icon='/media/icons/duotune/general/gen019.svg'
          title='Personalizar'
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>

      {/* TERMINA MI MENU */}

      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
