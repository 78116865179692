/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const QuickLinks: FC = () => (
  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
    >
      <h3 className='text-white fw-bold mb-3'>Acceso Rapido</h3>

      {/* <span className='badge bg-primary py-2 px-3'>1 tarea pendiente</span> */}
    </div>

    <div className='row g-0'>
      <div className='col-6'>
        <Link
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          to={'/apps/ordenes/lista'}
        >
          <KTSVG
            path='/media/icons/custom/order.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Compras</span>
          <span className='fs-7 text-gray-400'>Proveeduria</span>
        </Link>
      </div>

      <div className='col-6'>
        <Link
          to={'/apps/requerimientos/lista'}
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
        >
          <KTSVG
            path='/media/icons/custom/request.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Requisiciones</span>
          <span className='fs-7 text-gray-400'>Solicitudes</span>
        </Link>
      </div>

      <div className='col-6'>
        <Link
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          to={'/apps/proyectos/lista'}
        >
          <KTSVG
            path='/media/icons/custom/machine.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Proyectos</span>
          <span className='fs-7 text-gray-400'>Inversiones</span>
        </Link>
      </div>

      <div className='col-6'>
        <Link
          to={'/apps/clientes/lista'}
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'
        >
          <KTSVG
            path='/media/icons/duotune/finance/fin006.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Clientes</span>
          <span className='fs-7 text-gray-400'>Ultimos contratos</span>
        </Link>
      </div>
    </div>

    {/* <div className='py-2 text-center border-top'>
      <Link to='/crafted/pages/profile' className='btn btn-color-gray-600 btn-active-color-primary'>
        View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
      </Link>
    </div> */}
  </div>
)

export {QuickLinks}
