// import {useDebounce} from '../../../../_metronic/helpers'
import {useDebounce} from 'use-debounce'
import {debounce} from '../../../helpers/utils'
import {AuthModel} from './_models'
import {refresh_token} from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      if (auth.api_token) {
        if (!verifyToken(auth.api_token)) {
          setAuth({...auth, api_token: ''})
          // console.log('vamos a refrescar eltoken')
          refrescarToken(auth.refresh_token)
          // debounce(refrescarToken(auth.refresh_token), 15000)
          // useDebounce(refrescarToken(auth.refresh_token), 1000)
        }
      }

      // console.log('auth', auth)

      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

//BEGIN: VALIDAR TOKEN

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

const verifyToken = (token: string) => {
  const decodedJwt = parseJwt(token)

  if (decodedJwt.exp * 1000 < Date.now()) {
    // props.logOut()
    console.error('Se vencio el token')
    return false
  }
  // console.log('token valido')
  return true
}

const refrescarToken = async (access_token: string) => {
  // console.log('refrescando')
  const res = await refresh_token(access_token)
  // const res = access_token
  // auth={api_token: , refresh: access_token}
  if (res.data) {
    setAuth({api_token: res?.data?.access || '', refresh_token: access_token})
  }
  // console.log('res refresh', res)
  // return res
}
// END: VALIDAR TOKEN

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
