import {Suspense, useEffect} from 'react'
import OneSignal from 'react-onesignal'
// import {ToastContainer} from 'react-bootstrap'
import {Outlet} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'

const App = () => {
  useEffect(() => {
    OneSignal.init({
      appId: process.env.ONESIGNAL_API_ID || '4e51eb3f-d5eb-4f3d-8387-3213f1030e28',
      allowLocalhostAsSecureOrigin: true,
    })
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      <ToastContainer theme='colored' bodyClassName='toastBody' />
    </Suspense>
  )
}

export {App}
